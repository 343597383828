<template>
  <div>
    <section class="bg-white p-3 rounded mt-3">
      <b-table
        id="questionnaire-table"
        striped
        hover
        :items="formattedItems"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        small
      >
        <template #cell(answer)="data">
          <b-form-checkbox-group
            class="checkbox-group-answer d-flex"
            style="gap: 1.5rem; width: max-content"
            v-model="data.item.answer"
            @change="updateAnswer(data.item.id, data.item.answer)"
            stacked
          >
            <b-form-checkbox
              class="custom-checkbox"
              :value="option.value"
              :disabled="checkboxDisabled === true"
              v-for="option in checkboxOptions"
              :key="option.value"
            >
              {{ option.label }}
            </b-form-checkbox>
          </b-form-checkbox-group>
        </template>
      </b-table>

      <b-pagination
        align="right"
        class="mb-2 mb-sm-0"
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
      ></b-pagination>
      <div class="signature-container m-3">
        <img v-if="signatureImage" :src="signatureImage" alt="" />
        <VueSignaturePad
          v-if="mode !== 'dokter'"
          class="border rounded mx-auto"
          style="height: 200px"
          ref="signaturePad"
        />
        <b-button
          v-if="mode !== 'dokter'"
          class="mt-3"
          @click="resetSignature"
          variant="danger"
          >Reset</b-button
        >
      </div>

      <div class="d-flex justify-content-end">
        <b-button
          v-if="editable === true"
          class="mt-2 mt-sm-0"
          variant="primary"
          @click="showModal = true"
          >Simpan</b-button
        >
      </div>
    </section>

    <b-modal
      id="modal"
      class="bg-black-0 rounded pr-16 pr-sm-32 mx-0 d-flex column mt-3"
      v-model="showModal"
      centered
      hide-header
      hide-footer
    >
      <h5 class="mb-0">
        {{
          questionnaireType === "add"
            ? "Konfirmasi Edit Kuesioner Pendonor"
            : "Konfirmasi Tambah Kuesioner Pendonor"
        }}
      </h5>
      <p>
        {{
          questionnaireType === "add"
            ? "Apakah anda akan melakukan edit kuesioner pada Pendonor Atas Nama"
            : "Apakah anda akan menambahkan kuesioner pada Pendonor Atas Nama"
        }}
        <span class="font-weight-bold">{{ pendonorName }}</span>
        ?
      </p>
      <div class="d-flex justify-content-end flex-wrap mt-32">
        <b-button variant="text" @click="showModal = false">Tutup</b-button>
        <b-button
          class="ml-16"
          variant="primary"
          :disabled="isLoading"
          @click="handleAction"
        >
          {{ questionnaireType === "add" ? "Ya, Edit" : "Ya, Simpan" }}
          <b-spinner small label="Loading" v-if="isLoading"></b-spinner>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BTable,
  BModal,
  BPagination,
  BFormCheckbox,
  BFormCheckboxGroup,
  BSpinner,
  BFormFile,
  BFormGroup,
  BRow,
  BCol,
  BImg,
} from "bootstrap-vue";
import AktivasiPendonorService from "../../../../../../../api/managependonor/managePendonorAPI";
import manageQuestionnaireAPI from "@/api/managequestionnaire/manageQuestionnaireAPI";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BModal,
    BTable,
    BPagination,
    BFormCheckbox,
    BFormCheckboxGroup,
    BSpinner,
    BFormFile,
    BFormGroup,
    BImg,
  },
  props: {
    registration_id: {
      type: String,
      required: true,
    },
    pendonorName: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: true,
    },
    questionnaireType: {
      type: String,
      required: false,
    },
    checkboxDisabled: {
      type: Boolean,
      required: false,
    },
    mode: String,
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 21,
      showModal: false,
      fields: [
        { key: "id", label: "ID", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "answer", label: "Answer", sortable: false },
      ],
      items: [],
      answer: [],
      disabledOptions: {},
    };
  },
  computed: {
    checkboxOptions() {
      return [
        { value: "ya", label: "Ya" },
        { value: "tidak", label: "Tidak" },
      ];
    },
    formattedItems() {
      return this.items.map((item) => {
        const answerObj = this.answer.find(
          (ans) => Number(ans.question_id) === item.id
        );
        return {
          ...item,
          answer: answerObj ? [answerObj.answer.toLowerCase()] : [],
        };
      });
    },
  },
  async mounted() {
    try {
      await this.fetchQuestionnaireRegistration();
      await this.fetchQuestionnaireList(this.currentPage);
    } catch (error) {
      console.error("Error during mounted lifecycle:", error);
    }
  },
  methods: {
    resetSignature() {
      if (this.$refs.signaturePad) {
        this.$refs.signaturePad.clearSignature();
      }
    },
    async fetchQuestionnaireList(pageNumber) {
      try {
        const response = await manageQuestionnaireAPI.getAll({
          per_page: 50,
          page: pageNumber,
        });
        this.items = response.data.data.data.map(({ id, description }) => ({
          id,
          description,
          answer: [],
        }));
        this.totalRows = response.data.data.total;
        this.updateItemsWithAnswers();
      } catch (error) {
        console.error("fetchQuestionnaireList failed:", error);
      }
    },
    async fetchQuestionnaireRegistration() {
      try {
        const response =
          await AktivasiPendonorService.listRegistrationQuestionnaire(
            this.registration_id
          );
        const data_answer = JSON.parse(response.data.data.data_answer);
        this.answer = Array.isArray(data_answer)
          ? data_answer
          : JSON.parse(data_answer);
        this.answer = this.answer.map((item) => ({
          ...item,
          answer: item.answer.toUpperCase(),
          question_id: String(item.question_id),
        }));
        this.signatureImage = response.data.data.signature_image;
        this.updateItemsWithAnswers();
      } catch (error) {
        console.error("Error fetching questionnaire registration:", error);
      }
    },
    updateItemsWithAnswers() {
      const disabledOptions = {};
      this.items.forEach((item) => {
        const answerObj = this.answer.find(
          (ans) => String(ans.question_id) === String(item.id)
        );
        if (answerObj && answerObj.answer) {
          disabledOptions[item.id] = { ya: true, tidak: true };
        } else {
          disabledOptions[item.id] = { ya: false, tidak: false };
        }
      });
      this.disabledOptions = disabledOptions;
    },
    isCheckboxDisabled(questionId, value) {
      return this.disabledOptions[questionId]?.[value.toLowerCase()] ?? false;
    },
    updateAnswer(questionId, answer) {
      const itemIndex = this.items.findIndex((item) => item.id === questionId);
      if (itemIndex !== -1) {
        if (answer.length > 1) {
          answer = [answer[answer.length - 1].toUpperCase()];
        } else if (answer.length === 1) {
          answer = [answer[0].toUpperCase()];
        }
        this.$set(this.items[itemIndex], "answer", answer);
        const answerIndex = this.answer.findIndex(
          (ans) => String(ans.question_id) === String(questionId)
        );
        if (answerIndex !== -1) {
          this.$set(this.answer, answerIndex, {
            question_id: String(questionId),
            answer: answer[0],
          });
        } else {
          this.answer.push({
            question_id: String(questionId),
            answer: answer[0],
          });
        }
        if (answer.length === 1) {
          this.disabledOptions[questionId] = {
            ya: answer[0].toLowerCase() === "tidak",
            tidak: answer[0].toLowerCase() === "ya",
          };
        } else {
          this.disabledOptions[questionId] = { ya: false, tidak: false };
        }
      }
    },
    async handleAction() {
      try {
        this.isLoading = true;

        const signatureDataUrl = this.$refs.signaturePad.saveSignature().data;
        const response = await fetch(signatureDataUrl);
        const blob = await response.blob();
        const signatureFile = new File([blob], "signature.png", {
          type: "image/png",
        });

        const formData = new FormData();
        formData.append("registration_id", this.registration_id);
        formData.append("data_answer", JSON.stringify(this.answer)), // remove all backslashes
          formData.append("image", signatureFile);

        if (this.questionnaireType === "add") {
          await AktivasiPendonorService.addRegistrationQuestion(formData);
        } else {
          await AktivasiPendonorService.updateRegistrationQuestion(formData);
        }

        this.showModal = false;
        this.$bvToast.toast("Kuesioner berhasil disimpan", {
          title: "Berhasil",
          variant: "success",
          solid: true,
        });
      } catch (error) {
        console.error("Error in handleAction:", error);
        this.$bvToast.toast("Terjadi kesalahan, silakan coba lagi.", {
          title: "Gagal",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.checkbox-group-answer {
  gap: 1.5rem;
  width: max-content;
}
.signature-container img {
  max-width: 100%;
  height: auto;
  margin-bottom: 1rem;
}
</style>
