<template>
  <b-form>
    <b-row>
      <b-col>
        <b-form-group id="input-group-1">
          <label for="tekanan_darah">Tekanan Darah</label>
          <b-form-input
            id="tekanan_darah"
            v-model="data.blood_pressure"
            type="number"
            :state="
              validate.blood_pressure.$dirty
                ? !validate.blood_pressure.$error
                : null
            "
            class="mb-3"
            placeholder="Masukkan Tekanan Darah Pasien"
          />
          <b-form-invalid-feedback
            :state="
              validate.blood_pressure.$dirty
                ? !validate.blood_pressure.$error
                : null
            "
            class="text-danger"
          >
            Tekanan Darah Harus Diisi
          </b-form-invalid-feedback>
          <label for="berat-badan">Berat Badan</label>
          <b-form-input
            id="berat-badan"
            v-model="data.weight"
            type="number"
            :state="validate.weight.$dirty ? !validate.weight.$error : null"
            class="mb-3"
            placeholder="Masukkan Berat Badan Pasien"
          />
          <b-form-invalid-feedback
            :state="validate.weight.$dirty ? !validate.weight.$error : null"
            class="text-danger"
          >
            Berat Badan Harus Diisi
          </b-form-invalid-feedback>
          <label for="tinggi-badan">Tinggi Badan</label>
          <b-form-input
            id="tinggi-badan"
            v-model="data.height"
            :state="validate.height.$dirty ? !validate.height.$error : null"
            class="mb-3"
            placeholder="Masukkan Tinggi Badan Pasien"
          />
          <b-form-invalid-feedback
            :state="validate.height.$dirty ? !validate.height.$error : null"
            class="text-danger"
          >
            Tinggi Badan Pasien Harus Diisi
          </b-form-invalid-feedback>

          <label for="keadaan-umum">Keadaan Umum</label>
          <b-form-input
            id="keadaan-umum"
            v-model="data.general_condition"
            :state="
              validate.general_condition.$dirty
                ? !validate.general_condition.$error
                : null
            "
            class="mb-3"
            placeholder="Masukkan Keadaan Umum Pasien"
          />
          <b-form-invalid-feedback
            :state="
              validate.general_condition.$dirty
                ? !validate.general_condition.$error
                : null
            "
            class="text-danger"
          >
            Keadaan Umum Pasien Harus Diisi
          </b-form-invalid-feedback>
          <label>Denyut Nadi</label>
          <b-form-input
            id="input-1"
            v-model="data.pulse"
            type="number"
            :state="validate.pulse.$dirty ? !validate.pulse.$error : null"
            class="mb-3"
            placeholder="Masukkan Denyut Nadi Pasien"
          />
          <b-form-invalid-feedback
            :state="validate.pulse.$dirty ? !validate.pulse.$error : null"
            class="text-danger"
          >
            Denyut Nadi Pasien Harus Diisi
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="input-group-2">
          <label>Suhu</label>
          <b-form-input
            id="input-1"
            v-model="data.temperature"
            type="number"
            :state="
              validate.temperature.$dirty ? !validate.temperature.$error : null
            "
            class="mb-3"
            placeholder="Masukkan Suhu Pasien"
            required
          />
          <b-form-invalid-feedback
            :state="
              validate.temperature.$dirty ? !validate.temperature.$error : null
            "
            class="text-danger"
          >
            Suhu Harus Diisi
          </b-form-invalid-feedback>
          <label>Riwayat Medis</label>
          <b-form-input
            id="input-1"
            v-model="data.medical_history"
            class="mb-3"
            placeholder="Masukkan Riwayat Medis Pasien"
            :state="
              validate.medical_history.$dirty
                ? !validate.medical_history.$error
                : null
            "
          />
          <b-form-invalid-feedback
            :state="
              validate.medical_history.$dirty
                ? !validate.medical_history.$error
                : null
            "
            class="text-danger"
          >
            Riwayat Medis Pasien Harus Diisi
          </b-form-invalid-feedback>
          <label>Metode Pengambilan Darah</label>
          <b-form-select
            v-model="data.collection_method"
            :state="
              validate.collection_method.$dirty
                ? !validate.collection_method.$error
                : null
            "
            class="mb-3"
            :options="optionsDarah"
          />
          <b-form-invalid-feedback
            :state="
              validate.collection_method.$dirty
                ? !validate.collection_method.$error
                : null
            "
            class="text-danger"
          >
            Metode Pengambilan Darah Harus Diisi
          </b-form-invalid-feedback>
          <label>Kantong</label>
          <b-form-select
            v-model="data.bag"
            class="mb-3"
            :options="optionsKantong"
            :state="validate.bag.$dirty ? !validate.bag.$error : null"
          />
          <b-form-invalid-feedback
            :state="validate.bag.$dirty ? !validate.bag.$error : null"
            class="text-danger"
          >
            Pemilihan Kantong Darah harus diisi
          </b-form-invalid-feedback>
          <label for="cc">CC</label>
          <b-form-radio-group
            v-model="data.cc"
            :state="validate.cc.$dirty ? !validate.cc.$error : null"
            class="mb-3"
          >
            <b-form-radio id="radio-1" value="350" required>
              350 cc
            </b-form-radio>
            <b-form-radio id="radio-2" value="450" required>
              450 cc
            </b-form-radio>
          </b-form-radio-group>
          <b-form-invalid-feedback
            :state="validate.cc.$dirty ? !validate.cc.$error : null"
            class="text-danger"
          >
            Please select an option
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="d-flex justify-content-end">
      <b-button
        variant="danger"
        class="mx-3"
        size="md"
        :disabled="acceptOrRejectionSuccess"
        @click="$emit('rejected')"
        >Rejected</b-button
      >
      <b-button
        variant="primary"
        class="mx-3"
        size="md"
        :disabled="acceptOrRejectionSuccess"
        @click="$emit('approved')"
        >Terima</b-button
      >
    </div>
  </b-form>
</template>

<script>
import {
  BForm,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormSelect,
  BFormInvalidFeedback,
  BFormInput,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";

export default {
  name: "FormDoctorExamination",
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInvalidFeedback,
    BFormInput,
    BFormRadio,
    BFormRadioGroup,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    validate: {
      type: Object,
      required: true,
    },
    optionsDarah: {
      type: Array,
      required: true,
    },
    optionsKantong: {
      type: Array,
      required: true,
    },
    acceptOrRejectionSuccess: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["approved", "reject"],
  created() {
    this.data.registration_id = this.$route.params.id;
  },
};
</script>
