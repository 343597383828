<template>
  <div>
    <b-card>
      <progress-bar-status :progress="1" />
    </b-card>

    <section>
      <ViewDoctorQuestionnaire
        :registration_id="currentDataPendonor.registration_id"
        :pendonorName="currentDataPendonor.name"
        :editable="true"
      />
    </section>

    <section>
      <b-row class="bg-black-0 rounded pr-16 pr-sm-32 mx-0 d-flex column mt-3">
        <b-col
          cols="12"
          class="pl-16 pl-sm-32 py-24 py-sm-32 overflow-hidden mb-10"
        >
          <h2>Diisi oleh Dokter</h2>
          <form-doctor-examination
            :data="aktivasidoctor"
            :validate="v$.aktivasidoctor"
            :options-darah="optionsDarah"
            :options-kantong="optionsKantong"
            :accept-or-rejection-success="acceptOrRejectionSuccess"
            @approved="handleApprovedModal"
            @rejected="handleRejectModal"
          />
        </b-col>
      </b-row>
      <b-modal id="modal" v-model="showModal" centered hide-header hide-footer>
        <div>
          <h5 v-if="modalType === 'accept'" class="mb-0">
            Konfirmasi Approved Status
          </h5>
          <h5 v-else-if="modalType === 'reject'" class="mb-0">
            Konfirmasi Rejected Status
          </h5>
          <h5 v-else class="mb-0">Konfirmasi Edit Kuesioner Pendonor</h5>

          <p v-if="modalType === 'accept'">
            Apakah anda akan menyetujui Pendonor Atas Nama
            <strong>{{ currentDataPendonor.name }}</strong>
            ?
          </p>
          <div v-else-if="modalType === 'reject'">
            <p>
              Apakah anda akan menolak Pendonor Atas Nama
              <strong>{{ currentDataPendonor.name }}</strong>
              ?
            </p>
            <div class="border-1">
              <b-form>
                <b-form-group label="Penyebab:">
                  <b-form-select
                    v-model="aktivasidoctor.rejectionReason"
                    :options="rejectionReasons"
                  ></b-form-select>
                </b-form-group>
                <b-form-group
                  v-if="aktivasidoctor.rejectionReason === 'lain-lain'"
                  label="Catatan:"
                >
                  <b-form-textarea
                    id="textarea-no-resize"
                    placeholder="Masukkan catatan..."
                    rows="3"
                    v-model="aktivasidoctor.note"
                    no-resize
                  ></b-form-textarea>
                </b-form-group>
              </b-form>
            </div>
          </div>

          <div class="d-flex justify-content-end flex-wrap mt-32">
            <b-button variant="text" @click="showModal = false">
              Tutup
            </b-button>
            <b-button
              v-model="isApproved"
              class="ml-16"
              :disabled="isLoading"
              :variant="modalType === 'accept' ? 'primary' : 'danger'"
              @click="handleAction"
            >
              {{ modalType === "accept" ? "Ya, Terima" : "Ya, Reject" }}
              <b-spinner small label="Loading" v-if="isLoading"></b-spinner>
            </b-button>
          </div>
        </div>
      </b-modal>
    </section>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BButton,
  BTable,
  BPagination,
  BSpinner,
  BFormCheckbox,
  BModal,
  BCard,
  BFormTextarea,
  BFormSelect,
  BFormGroup,
  BForm,
} from "bootstrap-vue";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import FormDoctorExamination from "./components/common/docter/FormDoctorExamination.vue";
import AktivasiPendonorService from "../../../../api/managependonor/managePendonorAPI";
import ProgressBarStatus from "./components/common/ProgressBarStatus.vue";
import ViewDoctorQuestionnaire from "./components/common/docter/ViewDoctorQuestionnaire.vue";

export default {
  name: "ViewDoctorStatus",
  setup: () => ({ v$: useVuelidate() }),
  components: {
    BCol,
    BRow,
    BButton,
    BTable,
    BPagination,
    BSpinner,
    BModal,
    BForm,
    BFormGroup,
    BFormCheckbox,
    BCard,
    FormDoctorExamination,
    BFormSelect,
    BFormTextarea,
    ProgressBarStatus,
    ViewDoctorQuestionnaire,
  },
  data() {
    return {
      labs: ["Registrasi", "Dokter", "HB", "Aftaf"],
      currentDataPendonor: null,
      showModal: false,
      intervalId: 0,
      acceptOrRejectionSuccess: false,
      routeName: "",
      routerPathname: "",
      modalType: "",
      isLoading: false,
      isBusy: false,
      selected: "",
      isApproved: false,
      fields: [
        { key: "question_id", sortable: false },
        { key: "description", sortable: false },
        { key: "answer", sortable: false },
      ],
      answer: [],
      data_answer: [],
      progress: 0,
      isQuestionaire: 0,
      listQuestionaireRegistration: {
        getAnswer: [
          { text: "", value: "YA", checked: false },
          { text: "", value: "TIDAK", checked: false },
          { text: "", value: "Diisi_Oleh_Petugas", checked: false },
        ],
      },
      checkedValues: [],
      aktivasidoctor: {
        name: "",
        registration_id: "",
        blood_pressure: "",
        pulse: "",
        temperature: "",
        medical_history: "",
        weight: "",
        height: "",
        general_condition: "",
        collection_method: null,
        cc: "",
        bag: null,
        rejectionReason: null,
        note: "",
        status: { Approved: "accept" },
      },
      rejectionReasons: [
        { value: null, text: "Pilih" },
        { value: "tensi rendah", text: "Tensi Rendah" },
        { value: "tensi tinggi", text: "Tensi Tinggi" },
        { value: "sedang menyusui", text: "Sedang Menyusui" },
        { value: "operasi gigi", text: "Operasi Gigi" },
        { value: "alergi", text: "Alergi" },
        { value: "lain-lain", text: "Lain-lain" },
      ],
      optionsDarah: [
        { text: "Pilih Metode Pengambilan Darah", value: null },
        { text: "Biasa", value: "Biasa" },
        { text: "Aferesis", value: "Aferesis" },
        { text: "Autologus", value: "Autologus" },
      ],
      optionsKantong: [
        { text: "Pilih Kantong", value: null },
        { text: "S", value: "S" },
        { text: "DK", value: "DK" },
        { text: "DB", value: "DB" },
        { text: "TR", value: "TR" },
        { text: "QD", value: "QD" },
      ],
    };
  },
  validations() {
    return {
      aktivasidoctor: {
        registration_id: { required },
        blood_pressure: { required },
        pulse: { required },
        temperature: { required },
        medical_history: { required },
        weight: { required },
        height: { required },
        general_condition: { required },
        collection_method: { required },
        cc: { required },
        bag: { required },
      },
    };
  },
  created() {
    this.getPendonor(this.$route.params.id);
    this.initial();
    this.routerPathname = this.$route.matched[0].path;
  },
  methods: {
    async initial() {
      this.isBusy = true;

      await this.getRegistrationsQuestionniare(this.$route.params.id);

      await new Promise((resolve) => {
        setTimeout(() => {
          this.isBusy = false;
          resolve();
        }, 1000);
      });
    },
    async getPendonor(id) {
      try {
        const response = await AktivasiPendonorService.getPendonor(id);
        this.currentDataPendonor = response.data.data[0];
        this.isQuestionaire = this.currentDataPendonor.is_questionnaire;
      } catch (err) {
        console.error(err);
      }
    },
    async storeDoctor() {
      const {
        registration_id,
        blood_pressure,
        pulse,
        temperature,
        medical_history,
        height,
        weight,
        general_condition,
        collection_method,
        cc,
        bag,
        rejectionReason,
        note,
      } = this.aktivasidoctor;

      const status = this.modalType === "accept" ? "Approved" : "Rejected";

      const formData = new FormData();
      formData.append("registration_id", registration_id);
      formData.append("blood_pressure", blood_pressure);
      formData.append("pulse", pulse);
      formData.append("temperature", temperature);
      formData.append("height", height);
      formData.append("weight", weight);
      formData.append("medical_history", medical_history);
      formData.append("general_condition", general_condition);
      formData.append("collection_method", collection_method);
      formData.append("cc", cc);
      formData.append("bag", bag);
      formData.append("status", status);
      formData.append("reason_rejected", rejectionReason);
      formData.append("note", note);

      await this.$store.dispatch("manageActivasiDoctor/storeDoctor", formData);

      this.$bvToast.toast("Data Berhasil Ditambahkan", {
        title: "Success",
        variant: "success",
        solid: true,
      });

      this.$router.push({ name: "doctors" });
    },

    openConfirmationModal(type) {
      this.v$.$touch();
      if (this.v$.aktivasidoctor.$invalid) {
        this.$bvToast.toast("Formulir tidak valid. Harap periksa kembali.", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
        return;
      }

      this.modalType = type;
      this.showModal = true;
    },
    handleApprovedModal() {
      this.openConfirmationModal("accept");
    },
    handleRejectModal() {
      this.openConfirmationModal("reject");
    },
    async handleAction() {
      this.isLoading = true;
      if (this.modalType === "accept" || this.modalType === "reject") {
        await this.storeDoctor();
      }

      this.showModal = false;
    },
  },
};
</script>

<style scoped>
#progress {
  -webkit-appearance: none;
  position: absolute;
  width: 95%;
  z-index: 5;
  margin-left: 22px;
  margin-bottom: 44px;
}
</style>
