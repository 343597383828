var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-form',[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-1"}},[_c('label',{attrs:{"for":"tekanan_darah"}},[_vm._v("Tekanan Darah")]),_c('b-form-input',{staticClass:"mb-3",attrs:{"id":"tekanan_darah","type":"number","state":_vm.validate.blood_pressure.$dirty
              ? !_vm.validate.blood_pressure.$error
              : null,"placeholder":"Masukkan Tekanan Darah Pasien"},model:{value:(_vm.data.blood_pressure),callback:function ($$v) {_vm.$set(_vm.data, "blood_pressure", $$v)},expression:"data.blood_pressure"}}),_c('b-form-invalid-feedback',{staticClass:"text-danger",attrs:{"state":_vm.validate.blood_pressure.$dirty
              ? !_vm.validate.blood_pressure.$error
              : null}},[_vm._v(" Tekanan Darah Harus Diisi ")]),_c('label',{attrs:{"for":"berat-badan"}},[_vm._v("Berat Badan")]),_c('b-form-input',{staticClass:"mb-3",attrs:{"id":"berat-badan","type":"number","state":_vm.validate.weight.$dirty ? !_vm.validate.weight.$error : null,"placeholder":"Masukkan Berat Badan Pasien"},model:{value:(_vm.data.weight),callback:function ($$v) {_vm.$set(_vm.data, "weight", $$v)},expression:"data.weight"}}),_c('b-form-invalid-feedback',{staticClass:"text-danger",attrs:{"state":_vm.validate.weight.$dirty ? !_vm.validate.weight.$error : null}},[_vm._v(" Berat Badan Harus Diisi ")]),_c('label',{attrs:{"for":"tinggi-badan"}},[_vm._v("Tinggi Badan")]),_c('b-form-input',{staticClass:"mb-3",attrs:{"id":"tinggi-badan","state":_vm.validate.height.$dirty ? !_vm.validate.height.$error : null,"placeholder":"Masukkan Tinggi Badan Pasien"},model:{value:(_vm.data.height),callback:function ($$v) {_vm.$set(_vm.data, "height", $$v)},expression:"data.height"}}),_c('b-form-invalid-feedback',{staticClass:"text-danger",attrs:{"state":_vm.validate.height.$dirty ? !_vm.validate.height.$error : null}},[_vm._v(" Tinggi Badan Pasien Harus Diisi ")]),_c('label',{attrs:{"for":"keadaan-umum"}},[_vm._v("Keadaan Umum")]),_c('b-form-input',{staticClass:"mb-3",attrs:{"id":"keadaan-umum","state":_vm.validate.general_condition.$dirty
              ? !_vm.validate.general_condition.$error
              : null,"placeholder":"Masukkan Keadaan Umum Pasien"},model:{value:(_vm.data.general_condition),callback:function ($$v) {_vm.$set(_vm.data, "general_condition", $$v)},expression:"data.general_condition"}}),_c('b-form-invalid-feedback',{staticClass:"text-danger",attrs:{"state":_vm.validate.general_condition.$dirty
              ? !_vm.validate.general_condition.$error
              : null}},[_vm._v(" Keadaan Umum Pasien Harus Diisi ")]),_c('label',[_vm._v("Denyut Nadi")]),_c('b-form-input',{staticClass:"mb-3",attrs:{"id":"input-1","type":"number","state":_vm.validate.pulse.$dirty ? !_vm.validate.pulse.$error : null,"placeholder":"Masukkan Denyut Nadi Pasien"},model:{value:(_vm.data.pulse),callback:function ($$v) {_vm.$set(_vm.data, "pulse", $$v)},expression:"data.pulse"}}),_c('b-form-invalid-feedback',{staticClass:"text-danger",attrs:{"state":_vm.validate.pulse.$dirty ? !_vm.validate.pulse.$error : null}},[_vm._v(" Denyut Nadi Pasien Harus Diisi ")])],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"id":"input-group-2"}},[_c('label',[_vm._v("Suhu")]),_c('b-form-input',{staticClass:"mb-3",attrs:{"id":"input-1","type":"number","state":_vm.validate.temperature.$dirty ? !_vm.validate.temperature.$error : null,"placeholder":"Masukkan Suhu Pasien","required":""},model:{value:(_vm.data.temperature),callback:function ($$v) {_vm.$set(_vm.data, "temperature", $$v)},expression:"data.temperature"}}),_c('b-form-invalid-feedback',{staticClass:"text-danger",attrs:{"state":_vm.validate.temperature.$dirty ? !_vm.validate.temperature.$error : null}},[_vm._v(" Suhu Harus Diisi ")]),_c('label',[_vm._v("Riwayat Medis")]),_c('b-form-input',{staticClass:"mb-3",attrs:{"id":"input-1","placeholder":"Masukkan Riwayat Medis Pasien","state":_vm.validate.medical_history.$dirty
              ? !_vm.validate.medical_history.$error
              : null},model:{value:(_vm.data.medical_history),callback:function ($$v) {_vm.$set(_vm.data, "medical_history", $$v)},expression:"data.medical_history"}}),_c('b-form-invalid-feedback',{staticClass:"text-danger",attrs:{"state":_vm.validate.medical_history.$dirty
              ? !_vm.validate.medical_history.$error
              : null}},[_vm._v(" Riwayat Medis Pasien Harus Diisi ")]),_c('label',[_vm._v("Metode Pengambilan Darah")]),_c('b-form-select',{staticClass:"mb-3",attrs:{"state":_vm.validate.collection_method.$dirty
              ? !_vm.validate.collection_method.$error
              : null,"options":_vm.optionsDarah},model:{value:(_vm.data.collection_method),callback:function ($$v) {_vm.$set(_vm.data, "collection_method", $$v)},expression:"data.collection_method"}}),_c('b-form-invalid-feedback',{staticClass:"text-danger",attrs:{"state":_vm.validate.collection_method.$dirty
              ? !_vm.validate.collection_method.$error
              : null}},[_vm._v(" Metode Pengambilan Darah Harus Diisi ")]),_c('label',[_vm._v("Kantong")]),_c('b-form-select',{staticClass:"mb-3",attrs:{"options":_vm.optionsKantong,"state":_vm.validate.bag.$dirty ? !_vm.validate.bag.$error : null},model:{value:(_vm.data.bag),callback:function ($$v) {_vm.$set(_vm.data, "bag", $$v)},expression:"data.bag"}}),_c('b-form-invalid-feedback',{staticClass:"text-danger",attrs:{"state":_vm.validate.bag.$dirty ? !_vm.validate.bag.$error : null}},[_vm._v(" Pemilihan Kantong Darah harus diisi ")]),_c('label',{attrs:{"for":"cc"}},[_vm._v("CC")]),_c('b-form-radio-group',{staticClass:"mb-3",attrs:{"state":_vm.validate.cc.$dirty ? !_vm.validate.cc.$error : null},model:{value:(_vm.data.cc),callback:function ($$v) {_vm.$set(_vm.data, "cc", $$v)},expression:"data.cc"}},[_c('b-form-radio',{attrs:{"id":"radio-1","value":"350","required":""}},[_vm._v(" 350 cc ")]),_c('b-form-radio',{attrs:{"id":"radio-2","value":"450","required":""}},[_vm._v(" 450 cc ")])],1),_c('b-form-invalid-feedback',{staticClass:"text-danger",attrs:{"state":_vm.validate.cc.$dirty ? !_vm.validate.cc.$error : null}},[_vm._v(" Please select an option ")])],1)],1)],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('b-button',{staticClass:"mx-3",attrs:{"variant":"danger","size":"md","disabled":_vm.acceptOrRejectionSuccess},on:{"click":function($event){return _vm.$emit('rejected')}}},[_vm._v("Rejected")]),_c('b-button',{staticClass:"mx-3",attrs:{"variant":"primary","size":"md","disabled":_vm.acceptOrRejectionSuccess},on:{"click":function($event){return _vm.$emit('approved')}}},[_vm._v("Terima")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }